@import "../../variables";

.tab-group {
    width: 100%;
  .tab-row {
    width: 100%;
    border-bottom: 0.1rem solid $gray-light;
    margin-bottom: 1rem;
  }
  .tab {
    background-color: transparent;
    border: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &.active {
      border: 1px solid $gray-light;
      border-bottom: none;
      background-color: $white;
      margin-bottom: -0.1rem;
    }
  }

  .tab-body {
    padding-top: 1rem;
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 25px;
    width: 100%;
  }
}
