.modal {
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-in-out;
  pointer-events: none;

  &-content {
    margin-top: 5%;
    min-width: 320px;
    border-radius: 5px;
    background-color: white;
    transition: all 0.5s ease-in-out;
    transform: scale(0);
  }

  &-header {
    width: 100%;
    padding: 1.5rem;
    background-color: #a067fe;
    color: #ffffff;
  }

  &-body {
    padding: 2rem;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
  }

  &.enter-done {
    opacity: 1;
    pointer-events: visible;

    .modal-content {
      transform: scale(1);
    }
  }

  &.exit {
    opacity: 0;

    .modal-content {
      transform: scale(0);
    }
  }
}
