$primary-color: #772cf3;
$primary-light-color: #a067fe;
$primary-lightest-color: #a067fe42;
$primary-dark-color: #31346a;
$gray: #656565;
$gray-light: #d7d7d7;
$gray-dark: #404040;
$white: #ffffff;
$white-dark: #f9f9f9;
$black: #000000;
$bright-yellow: #f7d800;
$success: #4caf50;
$danger: #f44336;
$warning: #f7d800;

// $secondary:     $gray-600;
// $success:       $green;
// $info:          $cyan;


// $light:         $gray-100;
// $dark:          $gray-900;