@import "../../variables";

.chip {
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  border-radius: 50px;
  padding: 7px 12px;
  border: 1px solid $gray;
  font-size: 1rem;
  cursor: pointer;
}
