* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.fixed-height-container {
  width: 100%;
  // max-height: 400px;
  overflow: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  position: relative;
  padding-bottom: 3rem;

  .next-btn,
  .previous-btn {
    // position: fixed;
    font-size: 1.5rem;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &__week {
      position: absolute;
      top: 5rem;
      height: 7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 20;
    }

    &__month {
      font-size: 1.5rem;
    }

    &.previous-btn__week{
      left:0;
    }
    &.next-btn__week{
      right:0;
    }
  }

}

.scheduler {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__month-bar {
    position:absolute;
    top:0;
    z-index: 20;
    width: 100%;
    min-height: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    background-color: white;
  }

  &__body {
    position: relative;
    // background-color: blue;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__days {
    width: 100%;
    padding:0 2rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    gap: 0.8rem;
  }

  &__day {
    position: relative;
    // width: 10rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    padding-top: 12rem;
  }

  &__day-title {
    position: absolute;
    top: 5rem;
    height: 7rem;
    
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;
    font-size: 0.8rem;
    background-color: white;
  }

  &__slot {
    max-width: 100%;
    min-width: 6rem;
    // height: 5rem;
    padding: 0.8rem;
    border-radius: 10px;
    background-color: #eae4f5;
    font-size: 0.7rem;
    text-align: center;

    &:hover {
      background-color: #772cf3;
      color: white;
      cursor: pointer;
    }

    // &.booked-by-other {
    //   background-color: #fd4242;
    //   color: black;
    // }

    &.booked {
      background-color: #772cf3;
      color: white;
    }

    &.available {
      background-color: #4caf50;
      color: white;
    }

    &.disabled {
      background-color: #ebeaea;
      color: black;
    }
  }


}

@media screen and (max-width: 820px) {
  .scheduler__days {
    display: block;
  }
  .scheduler__body {
    display: block;
  }
  .scheduler__day:not(:has(.scheduler__slot)) {
    display: none; /* Masquer les éléments .scheduler__day sans .scheduler__slot */
  }
  .scheduler__day-title {
    position: relative;
    top: 0;
    font-size: 1.6rem;
  }
  .scheduler__day{
    padding-top: 4rem;
    align-items: normal;
  }
  .next-btn,
  .previous-btn {
    margin-top: 10px;
    top: 0!important;
    height: 0!important;
  }
}
