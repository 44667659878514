@import "../../variables";

.single-select-button-group {
  display: block!important;

  button {
    margin: 5px;
    color: black;
    background: $white;
    border: 1px solid $primary-light-color;
    text-align: center;
    font-size: 1rem;
    padding: 0.5rem 2rem;
    cursor: pointer;

    &.active {
      background-color: $primary-light-color;
      color: $white;
    }
  }
}
